import { useIntl } from "react-intl";

import { useDefaultAnalyticsData } from "@bees-grow-shared/services";

import segmentAnalytics from "@/config/typewriter";

import { PocBasicInformation } from "../../types/pocInformationTypes";
import * as Styles from "./PocInformationHeader.styles";

interface PocInformationHeaderProps {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
  pocBasicInformation: PocBasicInformation;
  isLoading: boolean;
}

export default function PocInformationHeader({
  isDropdownOpen,
  setIsDropdownOpen,
  pocBasicInformation,
  isLoading,
}: PocInformationHeaderProps) {
  const { formatMessage } = useIntl();
  const defaultData = useDefaultAnalyticsData();

  function handleDropdown() {
    /* eslint-disable camelcase */
    setIsDropdownOpen((prev) => !prev);
    segmentAnalytics.buttonClicked({
      button_label: formatMessage({
        id: `PocInformation.${isDropdownOpen ? "SHOW_LESS" : "SHOW_MORE"}`,
      }),
      button_name: isDropdownOpen
        ? "customer_information_collapse"
        : "customer_information_expand",
      url: null,
      search_query: null,
      screen_name: "POC Information",
      ...defaultData,
    });
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Styles.CardHeader data-testid={`${PocInformationHeader.name}`}>
      <Styles.PocName
        size="H4"
        data-testid={`${PocInformationHeader.name}-poc-name`}
      >
        {pocBasicInformation.pocName}
      </Styles.PocName>
      <Styles.HeaderContent>
        <div>
          {pocBasicInformation.ownerName && (
            <>
              <Styles.OwnerTitle
                data-testid={`${PocInformationHeader.name}-owner-title`}
                weight="semibold"
                size="small"
              >
                {formatMessage({ id: "PocInformation.OWNER_NAME" })}
              </Styles.OwnerTitle>
              <Styles.OwnerName
                data-testid={`${PocInformationHeader.name}-owner-name`}
                weight="normal"
              >
                {pocBasicInformation.ownerName}
              </Styles.OwnerName>
            </>
          )}
        </div>
        <Styles.CollapsibleButton
          hasUnderline={false}
          onClick={handleDropdown}
          data-testid={`${PocInformationHeader.name}-collapsible-button`}
        >
          {isDropdownOpen
            ? formatMessage({ id: "PocInformation.SHOW_LESS" })
            : formatMessage({ id: "PocInformation.SHOW_MORE" })}
        </Styles.CollapsibleButton>
      </Styles.HeaderContent>
    </Styles.CardHeader>
  );
}

function Loading() {
  return (
    <Styles.CardHeader
      data-testid={`${PocInformationHeader.name}-${Loading.name}`}
    >
      <Styles.SkeletonLoaderPocName />
      <Styles.HeaderContent>
        <div>
          <Styles.SkeletonLoaderOwnerTitle />
          <Styles.SkeletonLoaderOwnerName />
        </div>
        <Styles.SkeletonLoaderCollapsibleButton />
      </Styles.HeaderContent>
    </Styles.CardHeader>
  );
}
