import { useCallback, useState } from "react";

import {
  ctiSharedService,
  PhoneResponseType,
  PocCallOrigin,
  RT4PublishCommandEnum,
  useSharedUnit,
} from "@bees-grow-shared/services";

interface UseCTIConnectionProps {
  phones: Array<PhoneResponseType>;
  pocCallOrigin: PocCallOrigin;
}

export function useCTIConnection({
  phones,
  pocCallOrigin,
}: UseCTIConnectionProps) {
  const ctiService = ctiSharedService();

  const { isConnected } = useSharedUnit(ctiService);
  const [callBestPhone, setCallBestPhone] = useState<boolean>(false);
  useState<boolean>(false);

  const handleStartCTI = useCallback(() => {
    const bestPhoneToCall = phones[0];

    if (
      pocCallOrigin !== PocCallOrigin.AUTO_DIALER &&
      !callBestPhone &&
      bestPhoneToCall
    ) {
      ctiService.send({
        command: RT4PublishCommandEnum.MAKE_CALL,
        payload: {
          phoneNumber: bestPhoneToCall.phoneNumber,
        },
      });

      setCallBestPhone(true);
    }
  }, [callBestPhone, ctiService, phones, pocCallOrigin]);

  return {
    isCTIConnected: isConnected,
    handleStartCTI,
  };
}
