import { useCallback, useEffect, useMemo } from "react";

import {
  accountSharedService,
  agentCallSharedService,
  OpenCallStateEnum,
  phoneRankSharedService,
  PocCallOrigin,
  useDefaultAnalyticsData,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import segmentAnalytics from "@/config/typewriter";
import { getCallAttempts } from "@/stores/openCall/OpenCallEvent";
import OpenCallStore from "@/stores/openCall/OpenCallStore";
import { upsertOpenCallUseCase } from "@/useCases/agentCall/upsertOpenCall/UpsertOpenCallUseCase";

import { useCTIConnection } from "../useCTIConnection/useCTIConnection";

interface UseCallTimerInfoProps {
  ctiEnabled: boolean;
  isLoading: boolean;
  isOnCall: boolean;
  isCTIConnected: boolean;
  attempts: number;
  toggleCallState: () => void;
}

export function useCallTimerInfo(
  onFinishCall?: () => void,
): UseCallTimerInfoProps {
  const phoneRankService = phoneRankSharedService();
  const { accountId, vendorId } = useSharedUnit(accountSharedService());
  const agentCallService = agentCallSharedService();
  const { callId, pocCallOrigin, startCallDate, openCall, ctiEnabled } =
    useSharedUnit(agentCallSharedService());
  const {
    isLoading: isLoadingPhoneRank,
    done: phoneRankDone,
    error: phoneRankError,
    phones,
  } = useSharedUnit(phoneRankService);

  const { callAttempts } = useUnit(OpenCallStore);

  const defaultData = useDefaultAnalyticsData();

  const { isCTIConnected, handleStartCTI } = useCTIConnection({
    phones,
    pocCallOrigin,
  });

  const isStartCallLoading = useMemo(
    () => (isLoadingPhoneRank || openCall.isLoading) && !openCall.done,
    [isLoadingPhoneRank, openCall.done, openCall.isLoading],
  );

  const isAutoDialer = useMemo(
    () => pocCallOrigin === PocCallOrigin.AUTO_DIALER,
    [pocCallOrigin],
  );

  const isGetNextClient = useMemo(
    () => pocCallOrigin === PocCallOrigin.GET_NEXT_CLIENT,
    [pocCallOrigin],
  );

  const isPhoneRankSuccess = useMemo(
    () => phoneRankDone && !phoneRankError && phones.length > 0,
    [phoneRankDone, phoneRankError, phones.length],
  );

  const isStartAutomatically = useMemo(
    () => isPhoneRankSuccess && isGetNextClient && isCTIConnected,
    [isCTIConnected, isGetNextClient, isPhoneRankSuccess],
  );

  const isStartedCall = useMemo(() => !!startCallDate, [startCallDate]);

  const initializeCall = useCallback(() => {
    const startCall = new Date();

    agentCallService.upsertOpenCall(
      upsertOpenCallUseCase({
        callId,
        vendorId,
        startCall,
        useCase: OpenCallStateEnum.START_CALL,
      }).then(() => {
        handleStartCTI();
      }),
    );
  }, [agentCallService, callId, handleStartCTI, vendorId]);

  const toggleCallState = () => {
    if (isStartedCall) {
      onFinishCall?.();
      segmentAnalytics.buttonClicked({
        button_label: "End Call",
        button_name: "end_call_button",
        url: null,
        search_query: null,
        screen_name: "POC Information",
        ...defaultData,
      });
    } else {
      initializeCall();
    }
  };

  useEffect(() => {
    if (callId && vendorId) {
      getCallAttempts({ accountId, vendorId });
    }
  }, [accountId, callId, vendorId]);

  useEffect(() => {
    if (
      (isAutoDialer || isStartAutomatically) &&
      !startCallDate &&
      !isStartedCall &&
      callId
    ) {
      initializeCall();
    }
  }, [
    callId,
    initializeCall,
    isAutoDialer,
    isStartAutomatically,
    isStartedCall,
    startCallDate,
  ]);

  return {
    isLoading: isStartCallLoading,
    ctiEnabled,
    isCTIConnected,
    isOnCall: !!startCallDate,
    attempts: callAttempts,
    toggleCallState,
  };
}
